import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modal_wrapper = _resolveComponent("modal-wrapper")!
  const _component_Notifications = _resolveComponent("Notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_modal_wrapper, { "modal-name": "confirm_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "locomotive_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "employee_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "tool_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "move_locomotive_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "choose_date_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "text_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "tool_employee_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "grid_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "material_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "merge_material_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "stock_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "process_engineer_form_two_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "power_calculation_edit_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "maintenance_standard_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "maintenance_exceptions_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "tool_employee_receipt_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "supplier_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "price_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "add_section_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "loading_ditches_list_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "item_equipment_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "material_requirement_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "new_user_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "edit_user_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "change_password_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "add_material_material_requirement_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "invoice_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "invoice_material_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "add_invoice_material_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "send_payment_order_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "section_job_summary_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "supplier_selection_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "supplier_selection_winner_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "supplier_selection_attachment_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "employee_tariff_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "department_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "close_purchase_request_from_stock_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "section_job_contract_number_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "invoice_from_to_be_received_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "select_locomotive_in_material_requirement_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "project_register_modal" }),
    _createVNode(_component_modal_wrapper, { "modal-name": "project_register_price_modal" }),
    _createVNode(_component_Notifications, { position: "bottom right" })
  ], 64))
}